@tailwind base;
@tailwind components;
@tailwind utilities;

body {
    margin: 0;
    padding: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color:rgb(243 244 246 / 1);
  }
  
  #root {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
  }

.bg-transparent {
    background-color: transparent !important;
  }
  
  .bg-black {
    background-color: black !important;
  }
  
  .transition-all {
    transition: all 0.3s ease;
  }
  